const BoConfig = {
  sidebar: [
    {name:"Dashboard",link:"BoDashboard",icon:"fas fa-tachometer-alt",},
    {name:"Pages",divider:'Pages',icon:'',link:''},
    {name:"Home Page",link:'BoHome',icon:'fas fa-home',},
    { name: "Cape Discovery",title:'Cape', icon: "fas fa-cube", childs: [
      { name: "The Process", link: "BoCapeProcess" },
      { name: "The Collection", link: "BoCapeCollection" },
      { name: "How to Enjoy", link: "BoCapeEnjoy" },
    ]},
    { name: "Bellisimo", title:'Belli',icon: "fas fa-cube", childs: [
      { name: "The Process", link: "BoBellProcess" },
      { name: "The Collection", link: "BoBellCollection" },
      { name: "How to Enjoy", link: "BoBellEnjoy" },
    ]},
    { name: "Meet Us",title:'Meet', icon: "fas fa-users", childs: [
      { name: "Our Story", link: "BoStory" },
      { name: "Our Blog", link: "AppPostBlog" },
      { name: "Our Cities", link: "AppCities" },
    ]},
    {name:"Subscribe",link:"AppSubscribe",icon:"fas fa-newspaper",},
    {name:"Talk To Us",link:"AppContactUs",icon:"fas fa-phone",},
    {name:"User Register",link:"AppUserRegister",icon:"fas fa-users",},
    {name:"Legal",link:"AppLegal",icon:"fas fa-newspaper",},
    {name:"Settings",divider:'Settings',icon:'',link:''},
    {name:"Settings", title:'Settings', icon:"fas fa-users",childs:[
      {name:"Web Settings",link:"WebSettings"},
      {name:"SEO",link:"BoStaticSeo"},
      {name:"Contact",link:"BoContactSetting"},
      {name:"Template",link:"AppTemplate"},
    ]},
    {name:"User Management",title:'UManagement', icon:"fas fa-users",childs:[
      {name:"Users",link:"BoUsers"},
      {name:"User Level",link:"BoUserLevel"},
    ]},
  ],
}

export default BoConfig